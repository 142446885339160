import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import {
  BasicDataTable,
  BasicPagination,
  getRequiredDateFormat,
} from "@datwyler/shared-components";

const headers = ["site", "device", "parameter", "value", "timestamp"];

const columnConfigs = {
  site: {
    colspan: 3,
    isCentralized: true,
  },
  device: {
    colspan: 3,
    isCentralized: true,
  },
  parameter: {
    colspan: 2,
    isCentralized: true,
  },
  value: {
    colspan: 2,
    isCentralized: true,
  },
  timestamp: {
    colspan: 2,
    isCentralized: true,
  },
};

const TelemetryTable = (props) => {
  const {
    devices,
    telemetryData,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRows,
  } = props;
  const [tableData, setTableData] = useState([[]]);
  const intl = useIntl();

  useEffect(() => {
    if (telemetryData) {
      setTableData(getData());
    }
  }, [telemetryData]);

  useEffect(() => {
    setTableData(getData());
  }, [page, rowsPerPage]);

  const getData = () => {
    const data = [];
    if (telemetryData) {
      telemetryData.forEach((telemetry) => {
        const deviceDetails = devices.find(
          (device) => device.id === telemetry.device.id
        );
        const siteName = deviceDetails.site?.name || "";
        const deviceName = telemetry.device?.name || "";
        let value = intl.formatMessage({ id: telemetry.value?.toLowerCase() })
          ? intl.formatMessage({ id: telemetry.value?.toLowerCase() })
          : telemetry.value;

        data.push({
          site: siteName,
          device: deviceName,
          parameter: telemetry.name,
          value: value,
          timestamp: getRequiredDateFormat(telemetry.time, "DD/MM/YYYY HH:mm"),
          additionalInfo: telemetry,
        });
      });
    }

    return data;
  };

  return (
    <Box sx={{ marginTop: "25px" }}>
      <BasicDataTable
        headers={headers}
        data={tableData}
        columnConfigs={columnConfigs}
        isHideCheckbox={true}
        isHideActions={true}
      />
      <BasicPagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalRows={totalRows}
      />
    </Box>
  );
};

export default TelemetryTable;
