import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useIntl } from "react-intl";
import Filters from "../components/Filters";
import TelemetryTable from "../components/TelemetryTable";
import {
  useLocation,
  useTelemetry,
  useDevice,
  BasicBreadcrumbs,
  BasicScreenHeader,
  useLoadingGif,
  TenantIdContext,
  getRequiredDateFormat,
  exportToExcel,
  DarkModeContext,
  useSnackbar,
} from "@datwyler/shared-components";

const filterDefaults = {
  sites: [],
  startTime: null,
  devices: [],
  parameters: [],
};

const HistoricalTelemetry = () => {
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const [telemetries, setTelemetries] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [filters, setFilters] = useState(filterDefaults);
  const intl = useIntl();
  const [allSites, setAllSites] = useState([]);
  const { tenantId }: any = useContext(TenantIdContext);
  const { isFetchTelemetryLoading, fetchTelemetryData, fetchTelemetry } =
    useTelemetry();
  const {
    isFetchTelemetryLoading: isFetchTelemetryForExportLoading,
    fetchTelemetryData: fetchTelemetryForExportData,
    fetchTelemetry: fetchTelemetryForExport,
  } = useTelemetry();
  const {
    isFetchLoading: isFetchLocationLoading,
    fetchLocationData,
    fetchLocations,
  } = useLocation();
  const {
    isFetchLoading: isFetchDeviceLoading,
    fetchDeviceData,
    fetchDevices,
  } = useDevice();
  const { colors }: any = useContext(DarkModeContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchLocations({
      variables: { tenantId: tenantId },
    });

    setFilters(filterDefaults);
    setTelemetries([]);
  }, [tenantId]);

  useEffect(() => {
    setIsLoading(
      isFetchLocationLoading ||
        isFetchDeviceLoading ||
        isFetchTelemetryLoading ||
        isFetchTelemetryForExportLoading ||
        false
    );
  }, [
    isFetchLocationLoading,
    isFetchDeviceLoading,
    isFetchTelemetryLoading,
    isFetchTelemetryForExportLoading,
  ]);

  useEffect(() => {
    if (fetchLocationData) {
      let allSites = [];
      fetchLocationData?.locations?.forEach((loc) => {
        allSites = allSites.concat(loc.sites);
      });

      setAllSites(allSites);
    }
  }, [fetchLocationData]);

  useEffect(() => {
    if (fetchTelemetryData?.telemetries?.page) {
      setTotalRows(fetchTelemetryData.telemetries.page.totalElements || 0);
    }

    if (fetchTelemetryData?.telemetries?.telemetries) {
      setTelemetries(fetchTelemetryData?.telemetries?.telemetries);
    }
  }, [fetchTelemetryData]);

  useEffect(() => {
    if (
      filters.startTime &&
      filters.devices.length > 0 &&
      filters.parameters.length > 0
    )
      fetchData();
  }, [page, rowsPerPage, filters]);

  useEffect(() => {
    if (fetchTelemetryForExportData?.telemetries?.telemetries) {
      exportToExcel(
        getExcelData(fetchTelemetryForExportData.telemetries.telemetries),
        intl.formatMessage({ id: "excel_filename_telemetry" })
      );
    }
  }, [fetchTelemetryForExportData]);

  const fetchData = () => {
    const startTime = filters.startTime;
    const deviceIds = filters.devices;
    let filtersToSend = "";
    filters.parameters.forEach((value, index) => {
      if (index === 0) filtersToSend = `name:${value}`;
      else filtersToSend = filtersToSend + `|name:${value}`;
    });

    fetchTelemetry({
      variables: {
        tenantId: tenantId,
        startTime: startTime,
        endTime: startTime + 86400 - 1, // +24hours
        deviceIds: deviceIds,
        page: { number: page, size: rowsPerPage },
        sort: ["time,desc"],
        filter: [filtersToSend],
      },
    });
  };

  const fetchDataForExcel = () => {
    // check if filters are not entered
    if (
      !filters.startTime ||
      filters.devices.length < 1 ||
      filters.parameters.length < 1
    ) {
      enqueueSnackbar(intl.formatMessage({ id: "no_filters" }), {
        variant: "error",
      });
      return;
    }

    const startTime = filters.startTime;
    const deviceIds = filters.devices;
    let filtersToSend = "";
    filters.parameters.forEach((value, index) => {
      if (index === 0) filtersToSend = `name:${value}`;
      else filtersToSend = filtersToSend + `|name:${value}`;
    });

    fetchTelemetryForExport({
      variables: {
        tenantId: tenantId,
        startTime: startTime,
        endTime: startTime + 86400 - 1, // +24hours
        deviceIds: deviceIds,
        page: { number: 0, size: 999999 }, // send a huge number
        sort: [],
        filter: [filtersToSend],
      },
    });
  };

  const getExcelData = (telemetries) => {
    const excelData = [];

    telemetries.forEach((telemetry) => {
      const deviceDetails = fetchDeviceData?.devices?.devices?.find(
        (device) => device.id === telemetry.device.id
      );
      const siteName = deviceDetails.site?.name || "";
      const deviceName = telemetry.device?.name || "";
      let value = intl.formatMessage({ id: telemetry.value?.toLowerCase() })
        ? intl.formatMessage({ id: telemetry.value?.toLowerCase() })
        : telemetry.value;

      if (telemetry.unit) value = value + " " + telemetry.unit;

      const row = {};
      row[intl.formatMessage({ id: "site" })] = siteName || "-";
      row[intl.formatMessage({ id: "device" })] = deviceName || "-";
      row[intl.formatMessage({ id: "parameter" })] =
        intl.formatMessage({ id: telemetry.name }) || "-";
      (row[intl.formatMessage({ id: "value" })] = value),
        (row[intl.formatMessage({ id: "timestamp" })] = telemetry.time
          ? getRequiredDateFormat(telemetry.time, "DD/MM/YYYY HH:mm")
          : "-");

      excelData.push(row);
    });
    return excelData;
  };

  const getLowerComponent = () => {
    return (
      <>
        <Divider sx={{ borderColor: colors.dividerColor }} />
        <Filters
          fetchDevices={fetchDevices}
          sites={allSites}
          devices={fetchDeviceData?.devices?.devices}
          tenantId={tenantId}
          filterDefaults={filterDefaults}
          setFilters={setFilters}
        />
      </>
    );
  };

  return (
    <Box sx={{ paddingTop: "76px", paddingLeft: "24px", paddingRight: "24px" }}>
      <LoadingGif />
      <BasicBreadcrumbs activePage={"Telemetry"} />
      <BasicScreenHeader
        title={intl.formatMessage({ id: "telemetry" })}
        exportButton={{
          label: intl.formatMessage({ id: "export" }),
          onClick: () => fetchDataForExcel(),
        }}
        LowerComponent={getLowerComponent()}
      />
      {telemetries && (
        <TelemetryTable
          telemetryData={telemetries}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalRows={totalRows}
          devices={fetchDeviceData?.devices?.devices}
        />
      )}
    </Box>
  );
};

export default HistoricalTelemetry;
