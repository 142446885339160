import { Route, Routes } from "react-router-dom";
import HistoricalTelemetry from "./containers/HistoricalTelemetry";

const Router = (props: any) => {
  return (
    <Routes>
      <Route path="/telemetry" element={<HistoricalTelemetry />} />
    </Routes>
  );
};

export default Router;
